

















import { Vue, Component, Ref, PropSync, Prop } from 'vue-property-decorator'
import request from '@/common/request'
import axios from 'axios'
import { Fragment } from 'vue-fragment'

@Component({
	components: {
		Fragment,
	},
})
export default class WrapperDeUpload extends Vue {
	@PropSync('value') url!: string
	@Prop({ type: String }) bucketName?: string
	@Prop({
		type: Function,
		default: undefined,
	}) onError?: (error: Error) => void
	@Ref() fileInput!: {
		$el: HTMLInputElement
	}
	@Prop({ type: String, default: 'default' }) folderName?: string
	carregando = false

	abrir() {
		const fileInput = this.fileInput.$el
		const button = fileInput.querySelector('button')
		if (button) button.click()
	}

	async upload(file: File) {
		if (!file) return

		try {
			this.carregando = true
			const presignedUrl = await request.post<PreSignedBody, string>('/pre-signed-url', {
				fileName: file.name,
				folderName: this.folderName,
				contentType: file.type,
				fileSize: file.size,
				bucketName: this.bucketName,
				httpMethod: 'PUT',
			})

			await axios.put(presignedUrl, file, {
				headers: {
					'Content-Type': file.type,
				},
			})

			const url = new URL(presignedUrl)
			url.search = ''

			this.$emit('input', url.toString())
		} catch (error) {
			if (this.onError) this.onError(error)
		} finally {
			this.carregando = false
		}
		
	}
}

interface PreSignedBody {
	fileName: string
	contentType: string
}

