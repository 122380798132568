










































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import BuscaDeGrupoEconomico from './BuscaDeGrupoEconomico.vue'
import { v4 as uuidv4 } from 'uuid'
import SeletorDeUsuario from '../usuario/SeletorDeUsuario.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { ConfiguracaoDeNotificacaoPorEmail } from '@/models'

@Component({
	components: {
		BuscaDeGrupoEconomico,
		SeletorDeUsuario,
	},
})
export default class DialogoDeConfiguracaoDeNotificacaoPorEmail extends Vue {
	@Prop() modulo!: 'Estoque' | 'Vendas' | 'Nota em Transito'
	@Prop() lojaId!: string
	mostra = false
	salvando = false
	configuracaoDeNotificacaoPorEmail: ConfiguracaoDeNotificacaoPorEmail | null = null
	configuracaoEmEdicao: ConfiguracaoDeNotificacaoPorEmail | null = null
	
	get configuracoesDoModulo() {
		if (!this.modulo) return []

		switch(this.modulo) {
			case 'Estoque': 
				return ['Estoque negativo']
			case 'Vendas': 
				return ['Venda com status inválido']
			case 'Nota em Transito':
				return ['Nota em trânsito inconsistente']
			default:
				throw Error('Módulo não esperado')
		}
	}

	@Watch('mostra')
	onChangeMostra() {
		if (!this.modulo) return
		if (!this.mostra) return
		if (!this.configuracaoDeNotificacaoPorEmail) {
			this.configuracaoDeNotificacaoPorEmail = this.novaConfiguracao()
		}

		if (!this.configuracaoDeNotificacaoPorEmail.id) {
			this.configuracaoEmEdicao = {
				id: uuidv4(),
				lojaId: this.lojaId,
				emails: [],
				modulo: this.modulo,
				tipoNotificacao: null,
			} as ConfiguracaoDeNotificacaoPorEmail
		} else {
			this.configuracaoEmEdicao = {
				id: this.configuracaoDeNotificacaoPorEmail.id,
				lojaId: this.configuracaoDeNotificacaoPorEmail.lojaId,
				emails: this.configuracaoDeNotificacaoPorEmail.emails,
				modulo: this.configuracaoDeNotificacaoPorEmail.modulo,
				tipoNotificacao: this.configuracaoDeNotificacaoPorEmail.tipoNotificacao,
			} as ConfiguracaoDeNotificacaoPorEmail
		}
	}

	salvar() {
		try {
			this.$emit('editar', this.configuracaoEmEdicao)
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.fechar()
		}
	}

	mostrar(config: ConfiguracaoDeNotificacaoPorEmail) {
		this.configuracaoDeNotificacaoPorEmail = config
		this.mostra = true
	}

	fechar() {
		this.mostra = false
		this.configuracaoEmEdicao = null
		this.configuracaoDeNotificacaoPorEmail = null
	}

	novaConfiguracao() {
		return {
			id: null,
			lojaId: this.lojaId,
			emails: [],
			modulo: 'Estoque',
			tipoNotificacao: null,
		} as ConfiguracaoDeNotificacaoPorEmail
	}
}

