
























import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator';
import DataTableDeCrud from '../ui/DataTableDeCrud.vue';

import { Cliente, Loja } from '@/models';
import { DataOptions } from 'vuetify';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import axios, { CancelTokenSource } from 'axios';
import { FindClienteUseCase, SaveLojaUseCase } from '@/usecases';
import DialogoDeVincularClienteComLoja from '@/views/application/lojas/EdicaoDeLoja/DialogoDeVincularClienteComLoja.vue';


@Component({
	components: {
		DataTableDeCrud,
		DialogoDeVincularClienteComLoja,
	},
})
export default class ListagemDeClientesPorLoja extends Vue {
	@Prop() loja!: Loja
	@Ref() dialogoDeVincularClienteComLoja!: DialogoDeVincularClienteComLoja

	carregando = true
	loading = false
	mostrar = false

	totalRegistros = -1

	headers = [
		{ text: 'Nome', value: 'razaoSocialOuNome' },
		{ text: 'CPF/CNPJ', value: 'cnpjOuCpf'},
	]

	cancelToken: CancelTokenSource | null = null
	clientes: Cliente[] = []

	findClienteUseCase = new FindClienteUseCase()
	saveLojaUseCase = new SaveLojaUseCase()
	
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	abrirDialogoDeVincualarClientes() {
		this.dialogoDeVincularClienteComLoja.mostrar()
	}

	@Watch('mostrar')
	created() {
		if(!this.mostrar) return 
		this.buscar()
	}

	@Watch('paginacao')
	async buscar () {
		if(!this.loja.id) return
		try {
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				lojaId: this.loja.id || undefined,
				size: this.paginacao.itemsPerPage,
			}
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const pagina = await this.findClienteUseCase.findClientesVinculadosComLoja(params, axiosConfig)
			this.clientes = pagina.content
			this.totalRegistros = pagina.totalElements
			this.carregando = false
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
		}
	}

	async updateClientes(clientesUpdate: Cliente[]) {
		if(!this.loja) return
		if(this.loja && !this.loja.clientes) this.loja.clientes = []
		clientesUpdate.forEach(cliente => {
			this.loja?.clientes.push(cliente)
		}); 

		try {
			await this.saveLojaUseCase.vincularClientesComLoja(this.loja.id, clientesUpdate)
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	async deleteCliente(indice: number) {
		this.loading = true
		const clienteSelecionado = this.clientes[indice]
		try {
			await this.saveLojaUseCase.desvincularLojaECliente(this.loja.id, clienteSelecionado.id)
		} catch(e) {
			this.loading = false
			AlertModule.setError(e)
		} finally {
			this.loading = false
			this.clientes.splice(indice, 1)
			AlertModule.setSuccess("Cliente desvinculado com sucesso")
		}
	}

	get clientesFormatadas() {
		return this.clientes
	}
}
