















































import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue';
import { Loja, Marca } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindMarcaUseCase } from '@/usecases';
import axios, { CancelTokenSource } from 'axios';
import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify';
import DialogoDeVincularMarcaComLoja from './DialogoDeVincularMarcaComLoja.vue';

@Component({
	components: {
		DialogoDeVincularMarcaComLoja,
		DataTableDeCrud,
	},
})

export default class ListagemDeMarcasPorLoja extends Vue {
	@Prop() loja!: Loja
	@Ref() dialogoDeVincularMarcaComLoja!: DialogoDeVincularMarcaComLoja
	
	findMarcaUseCase = new FindMarcaUseCase()
	cancelToken: CancelTokenSource | null = null
	marcas: Marca[] = []
	carregando = false
	editando = false
	totalDeRegistros = -1
	
	headers = [
		{ text: 'Marca', value: 'nome' },
		{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	abrirDialogoDeVincularMarcaComLoja() {
		this.editando = true
		this.dialogoDeVincularMarcaComLoja.mostrar()
	}

	async updateMarcas(marcasUpdate: Marca[]) {
		const listaFormatada = marcasUpdate.map(marca => ({id: marca.id, nome: marca.nome, lojas: []}))
		listaFormatada.forEach(marca => {
			this.marcas.push(marca)
		}); 
		
		this.$emit("update:marcas", listaFormatada)

	}

	async buscarPagina() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const pagina = await this.findMarcaUseCase.findMarcasDaLoja({
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				lojaId: (this.loja ? this.loja.id : ""), 
			}, axiosConfig)
			this.marcas = pagina.content
			this.totalDeRegistros = pagina.totalElements === 0 ? 1 : pagina.totalElements
		} catch(error) {
			if (error.message)
				AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	deletarMarca(marcaDeletar: Marca) {
		const idx = this.marcas.findIndex(marcaLoja => marcaLoja.id === marcaDeletar.id)
		if(idx != undefined) 
			this.$emit("deletar:marcas", this.marcas[idx])
		this.marcas.splice(idx, 1)
	}

	get itensFormatados(): Marca[] {
		return this.marcas
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscarPagina

}
