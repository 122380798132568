




































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import { FormLoja } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { v4 as uuidv4 } from 'uuid'

@Component({
	components: {
		SeletorDeLojas,
	},
})
export default class DialogoDeImportacaoDeOutrasLojas extends Vue {
	@Prop() idLojaAtual!: string
	@PropSync('value') loja!: FormLoja
	
	lojaSelecionada: FormLoja | null = null
	campos: CamposDaImportacao [] = []
	mostra = false

	async mostrar() {
		this.lojaSelecionada = null
		this.campos = []
		this.mostra = true
	}

	esconder() {
		this.mostra = false
	}

	importarDados() {
		if(!this.lojaSelecionada) {
			AlertModule.setError('Nenhuma loja selecionada')
			return
		}
		if(this.campos.length <= 0) {
			AlertModule.setError('Nenhum campo selecionado')
			return
		}

		let loja = {...this.loja}

		if (this.campos.includes('pagamentos')) {
			loja = {
				...loja,
				tiposDePagamento: [
					...loja.tiposDePagamento,
					...this.lojaSelecionada.tiposDePagamento.filter(lojaPagamento => {
						return !this.loja.tiposDePagamento.some(lojaSelecionadaPagamento => (
							lojaPagamento.nome === lojaSelecionadaPagamento.nome && lojaPagamento.formaDePagamento === lojaSelecionadaPagamento.formaDePagamento
						))
					})
						.map(tipo => ({
							...tipo, id: uuidv4(),
						})),
				],
			}
		}

		this.$emit('input', loja)
		this.mostra = false
		this.$emit('camposImportados', this.campos)

		AlertModule.setInfo({
			text: `<div class="d-flex align-self-center">
				Clique em
				<i style="margin-left: 5px; margin-right: 5px;" class="mdi mdi-24px mdi-check-bold"></i>
				para confirmar as alterações
			</div>`,
			timeout: -1,
		})
	}
}

export type CamposDaImportacao = 'pagamentos'

