
















































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { FormPontoDeVenda, Loja } from '@/models'
import ConfirmExclusionDialog from '@/components/ui/ConfirmExclusionDialog.vue'
import StatusDoPontoDeVenda from '@/components/loja/StatusDoPontoDeVenda.vue'
import DialogoDePontoDeVenda from '@/components/loja/DialogoDePontoDeVenda.vue'

@Component({
	components: {
		ConfirmExclusionDialog,
		DialogoDePontoDeVenda,
		StatusDoPontoDeVenda,
	},
})
export default class CardDeAcoesDoPdv extends Vue {
	@PropSync('value') pdv!: FormPontoDeVenda
	@Prop({ type: Boolean, default: false }) esconderAcoes!: boolean
	@PropSync('loja', { type: Object }) lojaSync?: Loja
}

