















































import { Loja, LojaSalvar } from '@/models';
import { obrigatorio } from '@/shareds/regras-de-form';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { criarLoja } from '@/shareds/loja-shareds';
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue';

@Component({
	components: {
		SeletorDeLojasDoUsuario,
	},
})
export default class DialogoDeVincularLojaComLoja extends Vue {
	@Prop() loja!: Loja
	mostra = false
	listaDeLojas: LojaSalvar[] = []

	obrigatorio = obrigatorio

	mostrar() {
		this.mostra = true
	}

	cancelar() {
		this.mostra = false
		this.listaDeLojas = []
	}

	concluir() {
		const listaDeLojasFormatados = this.listaDeLojas.map(lojaSalvar => lojaSalvar.loja as Loja)
		this.$emit('update', listaDeLojasFormatados)
		this.mostra = false
		this.listaDeLojas = []
	}

	get lojasListagem() {
		return this.listaDeLojas ? this.listaDeLojas : []
	}

	adicionarParametro(novaLoja: LojaSalvar, indice: any) {
		let podeAdicionar: boolean | true = true

		if (this.loja.id == novaLoja.loja.id) {
			AlertModule.setError(
				'Não é possível vincular a loja a si mesma. Por favor, selecione outra loja',
			)
			this.listaDeLojas.splice(indice, 1)
			podeAdicionar = false
		}

		const lojasDaLista = this.listaDeLojas.length > 1 ? this.listaDeLojas.filter(
			lojaSalvar =>
				lojaSalvar.loja.id === novaLoja.loja.id,
		) : []

		if (lojasDaLista.length > 1) {
			AlertModule.setError(
				'Essa loja já está selecionada',
			)
			podeAdicionar = false
			this.listaDeLojas.splice(indice, 1)
			return
		}

		const lojasDaLoja =  this.loja.lojas.length > 1 ? this.loja.lojas.filter(
			lojaSalvar =>
				lojaSalvar.id === novaLoja.loja.id,
		) : []

		if (lojasDaLoja.length > 0) {
			AlertModule.setError(
				'Essa loja já está vinculada',
			)
			this.listaDeLojas.splice(indice, 1)
			podeAdicionar = false
		}

		if (podeAdicionar) {
			novaLoja.loja.id != null
				? (this.listaDeLojas[indice] = novaLoja)
				: null
		}
	}

	adicionarNovaLoja() {
		this.listaDeLojas.push({
			loja: criarLoja(),
		})
	}
}
