





































import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator'
import FiltrosDeUsuario from './FiltrosDeUsuario.vue'
import { Usuario } from '@/models'
import { FindUsuarioUseCase } from '@/usecases'
import ListaDeUsuarios from './ListaDeUsuarios.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import axios, { CancelTokenSource } from 'axios'

@Component({
	components: {
		FiltrosDeUsuario,
		ListaDeUsuarios,
	},
})
export default class BuscaDeUsuario extends Vue  {
	@PropSync('value') selecionado!: Usuario
	@Prop({ type: Boolean, default: false }) filtrarSomenteUsuariosAtivos!: boolean
	@Prop({ type: Boolean, default: false }) filtrarSomenteVendedorAtivo!: boolean
	
	busca = ''
	findUsuarioUseCase = new FindUsuarioUseCase()
	page = 1
	totalPages = 1
	usuarios: Usuario[] = []
	cancelToken: CancelTokenSource | null = null
	loading = false

	async created() {
		await this.buscar()
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.loading = true
			this.cancelToken = axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const pageUsuarios = await this.findUsuarioUseCase.findAll({
				busca: this.busca || undefined,
				somenteUsuariosAtivos: this.filtrarSomenteUsuariosAtivos,
				somenteVendedorAtivo: this.filtrarSomenteVendedorAtivo,
				page: this.busca ? -1 : (this.page -1 || 0),
				size: 10,
			}, axiosConfig)
			this.usuarios = pageUsuarios.content
			this.totalPages = pageUsuarios.totalPages
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.loading = false
		}
	}

	@Watch('page')
	onChangePage = this.buscar

	@Watch('busca')
	onChangeBusca() {
		this.totalPages = 0
		this.buscar()
	}
}

