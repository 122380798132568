















































import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue';
import { Loja } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindLojaUseCase, SaveLojaUseCase } from '@/usecases';
import axios, { CancelTokenSource } from 'axios';
import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import DialogoDeVincularLojaComLoja from './DialogoDeVincularLojaComLoja.vue';

@Component({
	components: {
		DialogoDeVincularLojaComLoja,
		DataTableDeCrud,
	},
})

export default class ListagemDeMarcasPorLoja extends Vue {
	@Prop() loja!: Loja
	@Ref() dialogoDeVincularLojasComLoja!: DialogoDeVincularLojaComLoja
	
	findLojaUseCase = new FindLojaUseCase()
	saveLojaUseCase =  new SaveLojaUseCase()
	cancelToken: CancelTokenSource | null = null
	lojas: Loja[] = []
	carregando = false
	editando = false
	totalDeRegistros = -1
	
	headers = [
		{ text: 'Loja', value: 'nomeFantasia' },
		{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	abrirDialogoDeVincularLojasComLoja() {
		this.editando = true
		this.dialogoDeVincularLojasComLoja.mostrar()
	}

	async updateLojas(lojasUpdate: Loja[]) {
		if(!this.lojas) this.lojas = []

		lojasUpdate.forEach(loja => {
			this.lojas.push(loja)
		});

		const changeLoja = {...this.loja, lojas: lojasUpdate}

		try {
			this.saveLojaUseCase.vincularLojas(changeLoja)
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	async buscarPagina() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			if (this.loja && this.loja.id !== '') {
				const pagina = await this.findLojaUseCase.findLojasVinculadasByLojaId({
					page: this.paginacao.page - 1,
					size: this.paginacao.itemsPerPage,
					lojaId: this.loja.id, 
				}, axiosConfig)
				this.lojas = pagina.content
				this.totalDeRegistros = pagina.totalElements === 0 ? 1 : pagina.totalElements
			}
		} catch(error) {
			if (error.message)
				AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async deletarLoja(lojaDeletar: Loja) {
		const idx = this.lojas.findIndex(loja => loja.id === lojaDeletar.id)
		this.lojas.splice(idx, 1)
		try {
			this.saveLojaUseCase.desvincularLoja(this.loja.id, lojaDeletar.id)
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	get itensFormatados(): Loja[] {
		return this.lojas as Loja[]
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscarPagina

}
